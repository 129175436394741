import { combineReducers } from "redux"
import calenderReducer from "./calendar/"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import dataListReducerOrders from "./data-list-orders"
import dataListReducerClients from "./data-list-clients/"
import assCreationReducer from "./after-sale-service-creation/"
import clientsMgmntReducer from "./clients-management/"
import deliveryTimeReducer from "./delivery-time/"

const rootReducer = combineReducers({
  calendar: calenderReducer,
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataListOrders: dataListReducerOrders,
  dataListClients: dataListReducerClients,
  dataASSCreation: assCreationReducer,
  dataClientAccount: clientsMgmntReducer,
  dataDeliveryTime: deliveryTimeReducer
})

export default rootReducer
