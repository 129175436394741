const initialState = {
  deliveryTimes: null
}


const deliveryTimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_DELIVERY_TIME":
      return {
        ...state,
        deliveryTimes: action.data
      }
    case "SET_DELIVERY_TIME":
      state.deliveryTimes.forEach(element => {
        if (element.ref === action.params.ref) {
          element.text = action.params.text
        }
      });
      return {
        ...state,
        deliveryTimes: state.deliveryTimes
      }
    default:
      return state
  }
}

export default deliveryTimeReducer
