const initialState = {
  allData: null,
}

const dataListReducerClients = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_DATA_CLIENTS":
      return {
        ...state,
        allData: initialState.allData
      }
    case "GET_ALL_DATA_CLIENTS":
      return {
        ...state,
        allData: action.data
      }
    default:
      return state
  }
}

export default dataListReducerClients
