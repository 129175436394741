var moment = require('moment');
require('moment/locale/fr.js');

const initialState = {
  allData: null,
}



const dataListReducerOrders = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_DATA_ORDERS":
      return {
        ...state,
        allData: initialState.allData
      }
    case "GET_ALL_DATA_ORDERS":
      return {
        ...state,
        allData: action.data,
      }
    default:
      return state
  }
}

export default dataListReducerOrders
