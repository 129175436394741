import {
  photoRefs
} from "../../actions/after-sale-service-creation"

import { saveAs } from 'file-saver';


const initialState = {
  orderDetail: null,
  indexProductSelected: null,
  croppingImage: false,
  currentRefImportImage: null,
  currentImportImage: null,
  currentImportImageCrop: null,
  currentImportImageSize: null,
  currentImportImageCanvasSize: null,
  imgFullProduct: null,
  imgSpecificProduct1: null,
  imgSpecificProduct2: null,
  annotatingImgFullProduct: false,
  annotationImgFullProductDone: false,
  annotationImgFullProductDone: false
}


const assCreationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_FORM_ASS":
      return initialState
    case "SET_ORDER_DETAIL_ASS":
      return {
        ...state,
        orderDetail: action.orderDetail
      }
    case "SET_PRODUCT_SELECTED_ASS":
      return {
        ...state,
        indexProductSelected: action.index
      }
    case "UPDATE_PHOTO_ASS":
      const { photoRef, uri } = action.params
      var { croppingImage, currentRefImportImage, currentImportImage, imgFullProduct, imgSpecificProduct1, imgSpecificProduct2 } = state

      if (uri) {
        currentImportImage = uri
        currentRefImportImage = photoRef
        croppingImage = true
      }
      else {
        switch (photoRef) {
          case photoRefs[0]:
            imgFullProduct = null
            break
          case photoRefs[1]:
            imgSpecificProduct1 = null
            break
          case photoRefs[2]:
            imgSpecificProduct2 = null
            break
          default:
            break
        }
      }

      return {
        ...state,
        croppingImage,
        currentRefImportImage,
        currentImportImage,
        imgFullProduct,
        imgSpecificProduct1,
        imgSpecificProduct2
      }
    case "CROP_PHOTO_ASS":
      return {
        ...state,
        currentImportImage: action.file.img,
        currentImportImageCrop: action.file.crop,
        currentImportImageSize: action.file.imgSize,
        currentImportImageCanvasSize: action.file.canvasSize
      }
    case "ANNOTATE_PHOTO_ASS":
      return {
        ...state,
        currentImportImage: action.img,
        annotationImgFullProductDone: true
      }
    case "CANCEL_IMPORT_PHOTO_ASS":
      return {
        ...state,
        currentImportImage: null,
        currentImportImageCrop: null,
        currentImportImageSize: null,
        currentImportImageCanvasSize: null,
        currentRefImportImage: null,
        croppingImage: false,
        annotatingImgFullProduct: false,
        annotationImgFullProductDone: false,
      }
    case "NEXT_STEP_IMPORT_PHOTO_ASS":
      if (state.currentRefImportImage === photoRefs[0] && state.croppingImage) {

        return {
          ...state,
          croppingImage: false,
          annotatingImgFullProduct: true
        }
      }
      else {
        var imgFullProduct = state.imgFullProduct
        var imgSpecificProduct1 = state.imgSpecificProduct1
        var imgSpecificProduct2 = state.imgSpecificProduct2
        switch (state.currentRefImportImage) {
          case photoRefs[0]:
            imgFullProduct = state.currentImportImage
            break
          case photoRefs[1]:
            imgSpecificProduct1 = state.currentImportImage
            break
          case photoRefs[2]:
            imgSpecificProduct2 = state.currentImportImage
            break
          default:
            break
        }
        return {
          ...state,
          imgFullProduct,
          imgSpecificProduct1,
          imgSpecificProduct2,
          croppingImage: false,
          annotatingImgFullProduct: false,
          currentImportImage: null,
          currentImportImageCrop: null,
          currentImportImageSize: null,
          currentImportImageCanvasSize: null,
          currentRefImportImage: null,
        }
      }
    default:
      return state
  }
}

export default assCreationReducer
