export const login = (state = { userRole: "admin" }, action) => {
  switch (action.type) {
    case "LOGIN": {
      return { ...state, values: action.payload }
    }
    case "LOGOUT": {
      return { ...state, values: action.payload }
    }
    case "CHANGE_ROLE": {
      return { ...state, userRole: action.userRole }
    }
    default: {
      return state
    }
  }
}
