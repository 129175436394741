const initialState = {
  clientAccounts: null
}


const clientsMgmntReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_GET_CLIENT_ACCOUNTS":
      return {
        ...state,
        clientAccounts: initialState.clientAccounts
      }
    case "GET_CLIENT_ACCOUNTS":
      return {
        ...state,
        clientAccounts: action.data
      }
    default:
      return state
  }
}

export default clientsMgmntReducer
