import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

export const encrypt = (msg) => {
  return CryptoAES.encrypt(msg, process.env.REACT_APP_ENCRYPTION_KEY).toString();
}

export const decrypt = (msg) => {
  return CryptoAES.decrypt(msg, process.env.REACT_APP_ENCRYPTION_KEY).toString(CryptoENC);
}
