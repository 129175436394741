import axios from "axios"
import { history } from "../../../history"
import { encrypt, decrypt } from "../../../utility/encryption"
import { authDataHeader } from "../../../helpers/authHeader"
import { getUser } from "./userActions"
import { config } from '../../../config'

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

export const updateIsUserBlocked = async () => {
  const user = getUser()
  if (user && !user.isInternUser) {
    try {
      const options = {
        headers: { 'Authorization': authDataHeader() }
      }
      const result = await axios.get(process.env.REACT_APP_SERVICE_URI + "users/isBlocked/code_client=" + user.clientCode, options)
      if (user.isBlocked != result.data) {
        user.isBlocked = result.data
        localStorage.setItem('user', encrypt(JSON.stringify(user)))
      }
    }
    catch (e) {
    }
  }
  return false
}

export const resetClientCodeForInternUserIfNeeded = async (currentPath, futurePath, futurePathParameter) => {
  const user = getUser()
  if (user && user.isInternUser && !currentPath.startsWith("/clients") && futurePath === '/clients/mes_clients' && futurePathParameter === '') {
        user.clientCode = null
        localStorage.setItem('user', encrypt(JSON.stringify(user)))
  }
}

export const isLoggedIn = () => {
  const jsonUser = localStorage.getItem('user')
  let isLoggedIn = false
  if (jsonUser != null) {
    try {
      let user = JSON.parse(decrypt(jsonUser))
      isLoggedIn = user.isLoggedIn
    }
    catch (e) {
    }
  }
  return isLoggedIn
}

export const logOutIfNeeded = () => {
  const jsonUser = localStorage.getItem('user');
  if (jsonUser != null) {
    try {
      let user = JSON.parse(decrypt(jsonUser))

      const params = {
        clientCode: user.clientCode,
        password: user.password,
        email: user.email,
        isInternUser: user.isInternUser
      }

      const options = {
        headers: { 'Authorization': authDataHeader() },
      }

      axios.post(process.env.REACT_APP_SERVICE_URI + "users/checkUserPwd", params, options)
        .then((response) => {
          if (!response.data.isUserFound) {
            logout()
          }
        })
        .catch(() => { })
    }
    catch (e) {
      logout()
    }
  }
}

export const login = user => {


}

export const logout = () => {
  localStorage.removeItem('user')
  history.push("/")
}
