import { encrypt, decrypt } from "../../../utility/encryption"

export const getUser = () => {
    const jsonUser = localStorage.getItem('user');
    let user = null
    if (jsonUser != null) {
      try {
        user = JSON.parse(decrypt(jsonUser))
      }
      catch (e) {
      }
    }
    return user
  }

