import axios from "axios"
import { authDataHeader } from "../../../helpers/authHeader"
import { getUser } from "../auth/userActions"

export const photoRefs = ['photo_article_entier', 'photo_anomalie1', 'photo_anomalie2']

export const setOrderInfo = orderDetail => {
  return dispatch => dispatch({ type: "SET_ORDER_DETAIL_ASS", orderDetail })
}

export const setProductSelected = index => {
  return dispatch => dispatch({ type: "SET_PRODUCT_SELECTED_ASS", index })
}

export const updatePhotoASS = params => {
  return dispatch => dispatch({ type: "UPDATE_PHOTO_ASS", params })
}

export const onCroppingPhoto = file => {
  return dispatch => dispatch({ type: "CROP_PHOTO_ASS", file })
}

export const onAnnotationPhoto = img => {
  return dispatch => dispatch({ type: "ANNOTATE_PHOTO_ASS", img })
}

export const cancelImportImage = () => {
  return dispatch => dispatch({ type: "CANCEL_IMPORT_PHOTO_ASS" })
}

export const nextStepImportImage = () => {
  return dispatch => dispatch({ type: "NEXT_STEP_IMPORT_PHOTO_ASS" })
}

export const resetForm = () => {
  return dispatch => dispatch({ type: "RESET_FORM_ASS" })
}