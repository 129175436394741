import mock from "../mock"

export const searchResult = [
  {
    groupTitle: "Pages",
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: "accueil",
        title: "Accueil",
        link: "/",
        icon: "Home"
      },
      {
        id: 2,
        target: "Innformations du compte",
        title: "Innformations du compte",
        link: "/informations_compte",
        starred: false
      },
      {
        id: 3,
        target: "Commandes en cours",
        title: "Commandes en cours",
        link: "/commandes/en_cours",
        starred: false
      },
    ]
  }
]

mock.onGet("/api/main-search/data").reply(200, {
  searchResult
})
