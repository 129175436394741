export const Roles = {
    Salesperson: 1,
    SalesAdmin: 3,
    CommercialDirector: 2,
    ExecutiveAssistant: 4,
    Admin: 5,
    CustomerSupport: 6,
    Marketing: 7,
    Qualite: 8,
    CommercialAssistant: 9,
    Tester: 10,
    QHSE: 11
}
